import { selectAllUsers$, selectUserLoaded$ } from 'src/app/store/selectors/user-right.selectors';
import { Component, OnInit, ChangeDetectionStrategy, OnDestroy, Inject, ChangeDetectorRef } from '@angular/core';
import { AbstractControl, FormBuilder, ValidationErrors, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { ReplaySubject, Subscription, Observable, firstValueFrom } from 'rxjs';
import { User } from 'src/app/models/user';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UserRightService } from 'src/app/services/user-right.service';
import { distinctUntilChanged, map, shareReplay, switchMap, tap } from 'rxjs/operators';
import { Option } from 'src/app/models/user-right';
import { ItselfOrArray } from 'src/app/utils/utils';

export interface SelectUserDialogData {
    /** The users should have at least one of these specific roles */
    specificRoles?: (keyof Option)[];
    /** List of users to exclude from the selection */
    exclude?: User['id'][];
    /**
     * Mutliple or single selection
     * @default true
     */
    multiple?: boolean;
    /** Limit of items which can be selected */
    limit?: number;
}

@Component({
  selector: 'app-select-user',
  templateUrl: './select-user.component.html',
  styleUrls: ['./select-user.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SelectUserComponent implements OnInit, OnDestroy {

  public userForm = this._fb.group({
    users: this._fb.control<ItselfOrArray<User['id']>>([], Validators.required)
  });
  public searchControl = this._fb.control('');
  public users!: User[];
  public users$: ReplaySubject<User[]> = new ReplaySubject();
  public usersLoading: boolean;
  public selectedUsers$: Observable<User[]>;

  private _subscription = new Subscription();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: SelectUserDialogData,
    private userRightService: UserRightService,
    private _fb: FormBuilder,
    private store: Store,
    private _dialogRef: MatDialogRef<SelectUserComponent>,
    @Inject(MAT_DIALOG_DATA) private _usersToFilterOut: User[] = [],
    private _cd: ChangeDetectorRef,
  ) {
    this.data = {
        multiple: true,
        ...this.data,
    };
    if (this.data.limit && this.data.multiple) {
        this.userForm.controls.users.addValidators(
            (control: AbstractControl<User[], User[]>): ValidationErrors|null => {
                if (control.value.length > this.data.limit) {
                    return { overLimit: true }
                }
                return null
            }
        )
    }
  }

  ngOnInit(): void {
    this._subscription.add(
        this.store.select(selectUserLoaded$).pipe(
            map(loaded => !loaded),
        ).subscribe(
            loading => {
                this.usersLoading = loading;
                this.userForm.controls.users[loading ? 'disable' : 'enable']();
            }
        )
    );
    this.userRightService.checkUserLoaded();
    this._subscription.add(this.store.select(selectAllUsers$).subscribe(
      users => {
        this.users = users.filter(user => !this._usersToFilterOut?.some(({ id }) => user.id === id));
        this.users.sort(
          (a, b) => a.userId.localeCompare(b.userId)
        );
        if (Object.entries(this.data).length) {
            this.users = this.users.filter(
                ({ id, option }) => {
                    let shouldInclude = true;
                    if (shouldInclude && this.data.exclude) {
                        shouldInclude = !this.data.exclude.includes(id);
                    }
                    if (shouldInclude && this.data.specificRoles) {
                        shouldInclude = this.data.specificRoles.some(
                            (role) => !!option?.[role]
                        );
                    }
                    return shouldInclude;
                }
            );
        }
        this._filterUsers();
      }
    ));
    this._subscription.add(
      this.searchControl.valueChanges.subscribe(
        () => this._filterUsers()
      )
    );
    this.selectedUsers$ = this.userForm.controls.users.valueChanges.pipe(
        distinctUntilChanged(),
        map(value => {
            let selections: User['id'][];
            if (!(value instanceof Array)) {
                selections = [value];
            } else {
                selections = value;
            }
            return this.users.filter(
                ({ id }) => selections.includes(id)
            );
        }),
        tap((users) => {
            this._cd.markForCheck();
        }),
        shareReplay(1),
    );
  }

  /**
   * Filter the users with the searched text
   */
  private _filterUsers(): void {
    if (!this.users) {
      return;
    }
    const search = this.searchControl.value;
    if (!search) {
      this.users$.next([...this.users]);
    } else {
      this.users$.next([...this.users.filter(
        user => {
          const searchRegex = new RegExp(search, 'i');
          return searchRegex.test(user.userId) || searchRegex.test(user.email);
        }
      )]);
    }
  }

  /**
   * Close the dialog
   */
  public onClickCancel(): void {
    this._dialogRef.close();
  }

  /**
   * Close the dialog & pass the selected users
   */
  public async onFormSubmit(): Promise<void> {
    this._dialogRef.close(await firstValueFrom(this.selectedUsers$));
  }

  ngOnDestroy(): void {
    this._subscription.unsubscribe();
  }

}
